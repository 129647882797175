import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Flex, Grid, GridItem, Text } from '@chakra-ui/react';
import { useAccount } from 'wagmi';

import { CenteredSpinner } from '@/components/common/CenteredSpinner/CenteredSpinner';
import { useMyShops } from '@/hooks/useShops';

import { ConnectWalletButton } from '../common/ConnectWalletButton/ConnectWalletButton';
import { ShopCard } from '../ShopCard/ShopCard';

export const MyShops = () => {
  const { address, isConnected } = useAccount();
  const { myShopsList, myShopsRequest } = useMyShops(address || '');

  const navigate = useNavigate();
  const redirectToShop = useCallback(
    (id: any) => {
      navigate(`/shop/${id}`);
    },
    [navigate]
  );

  return (
    <Box>
      <Text textStyle="h2">My Shops</Text>

      {isConnected ? (
        <>
          <Grid mt="12px" templateColumns="1fr 1fr 1fr" gap="10px">
            {myShopsRequest.isLoading ? <CenteredSpinner /> : null}

            {myShopsList.map((shop: any) => (
              <GridItem key={shop.id} colSpan={1}>
                <ShopCard
                  {...shop}
                  title={shop.name}
                  network={'POLYGON'}
                  onClick={() => redirectToShop(shop.id)}
                />
              </GridItem>
            ))}
          </Grid>
        </>
      ) : (
        <Flex alignItems="center" justifyContent="center" width="100%" height="600px">
          <ConnectWalletButton />
        </Flex>
      )}
    </Box>
  );
};
