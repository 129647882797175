import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, GridItem, Text } from '@chakra-ui/react';

import { CenteredSpinner } from '@/components/common/CenteredSpinner/CenteredSpinner';
import { useShopsList } from '@/hooks/useShops';

import { ShopCard } from '../ShopCard/ShopCard';

export const Shops = () => {
  const navigate = useNavigate();
  const redirectToShop = useCallback(
    (id: any) => {
      navigate(`/shop/${id}`);
    },
    [navigate]
  );

  const { shopsList, shopsListRequest } = useShopsList(1, 99);

  return (
    <Box>
      <Text textStyle="h2">Shops</Text>
      <Grid mt="12px" templateColumns="1fr 1fr 1fr" gap="10px">
        {shopsListRequest.isLoading ? <CenteredSpinner /> : null}

        {shopsList.map((shop: any) => (
          <GridItem key={shop.id} colSpan={1}>
            <ShopCard
              {...shop}
              title={shop.name}
              network={'POLYGON'}
              onClick={() => redirectToShop(shop.id)}
            />
          </GridItem>
        ))}
      </Grid>
    </Box>
  );
};
