import { configureChains, createConfig } from 'wagmi';
import {
  // avalanche,
  avalancheFuji,
  bscTestnet,
  // goerli,
  // mainnet,
  polygonMumbai,
  // polygonZkEvm,
  // polygonZkEvmTestnet,
  // sepolia,
} from 'wagmi/chains';
import { InjectedConnector } from 'wagmi/connectors/injected';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { publicProvider } from 'wagmi/providers/public';

window.Buffer = require('buffer/').Buffer;

const testnetChains = [polygonMumbai, bscTestnet, avalancheFuji];
// const productionChains = [polygonZkEvm, mainnet, avalanche]

const { chains, publicClient, webSocketPublicClient } = configureChains(testnetChains, [
  publicProvider(),
]);

const metamaskConnector = new MetaMaskConnector({
  chains,
  options: {
    shimDisconnect: true,
    UNSTABLE_shimOnConnectSelectAccount: true,
  },
});

const injectedConnector = new InjectedConnector({
  chains,
  options: {
    name: 'Injected',
    shimDisconnect: true,
  },
});

const config = createConfig({
  autoConnect: true,
  connectors: [metamaskConnector, injectedConnector],
  publicClient,
  webSocketPublicClient,
});

export { chains, config, injectedConnector, metamaskConnector };
