import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import { useApi } from './useApi';

export const useShopsList = (page: number, limit: number) => {
  const { getShops } = useApi();

  const shopsListRequest = useQuery({
    queryKey: ['shop-list-request', page, limit],
    queryFn: async () => getShops(page, limit),
  });

  const shopsList = useMemo(() => {
    return shopsListRequest.data?.data.data || [];
  }, [shopsListRequest.data]);

  return { shopsList, shopsListRequest };
};

export const useMyShops = (owner: string) => {
  const { getShopsByOwner } = useApi();

  const myShopsRequest = useQuery({
    queryKey: ['my-shops-request', owner],
    queryFn: async () => getShopsByOwner(owner),
  });

  const myShopsList = useMemo(() => {
    return myShopsRequest.data?.data.data.filter((shop: any) => shop.isActive) || [];
  }, [myShopsRequest.data]);

  return { myShopsRequest, myShopsList };
};

export const useShopById = (id: string) => {
  const { getShopById } = useApi();

  const shopRequest = useQuery({
    queryKey: ['shop-request', id],
    queryFn: async () => getShopById(id),
  });

  const shopData = useMemo(() => {
    return shopRequest.data?.data.shopData || {};
  }, [shopRequest.data]);
  const shopSubscriptions = useMemo(() => {
    return shopRequest.data?.data.shopSubscriptions || [];
  }, [shopRequest.data]);

  return { shopData, shopSubscriptions, shopRequest };
};

export const useSubscription = (shopId: string, subId: string) => {
  const { getShopSubscriptionById } = useApi();

  const subscriptionRequest = useQuery({
    queryKey: ['subscription-request', shopId, subId],
    queryFn: async () => getShopSubscriptionById(shopId, subId),
  });

  const subscription = useMemo(() => {
    return subscriptionRequest.data?.data;
  }, [subscriptionRequest.data]);

  return { subscriptionRequest, subscription };
};
