import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const commonProps = {
  textStyle: 'button',
  color: 'white',

  _disabled: {
    bgColor: 'gray',
    opacity: 1,
    color: 'rgba(255, 255, 255, 0.5)',
    border: 'none',
    filter: 'none',

    _hover: {},
  },
};

const primary = defineStyle({
  ...commonProps,
  bgColor: 'black',
  paddingTop: 15,
  paddingBottom: 15,
  paddingLeft: 30,
  paddingRight: 30,
  border: '2px solid black',

  _hover: {
    bgColor: 'white',
    color: 'black',

    _disabled: {
      bgColor: 'gray',
      color: 'rgba(255, 255, 255, 0.5)',
    },
  },
});

const secondary = defineStyle({
  ...commonProps,
  bgColor: 'rgba(100, 100, 100, 1)',
  paddingTop: 15,
  paddingBottom: 15,
  paddingLeft: 30,
  paddingRight: 30,
  border: '2px solid rgba(100, 100, 100, 1)',

  _hover: {
    bgColor: 'white',
    color: 'black',

    _disabled: {
      bgColor: 'gray',
      color: 'rgba(255, 255, 255, 0.5)',
    },
  },
});

export const buttonTheme = defineStyleConfig({
  variants: {
    primary,
    secondary,
  },
  sizes: {
    lg: {
      height: '60px',
    },
    md: {
      height: '50px',
    },
  },
  defaultProps: {
    variant: 'primary',
    size: 'md',
  },
});
