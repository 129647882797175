import { useCallback, useState } from 'react';
import { useAccount, usePublicClient, useWalletClient } from 'wagmi';

import { Subscription } from '@/components/CreateShop/SubscriptionModal';

import { ContractsEnum, useContractAbi } from './contracts/useContractAbi';
import { useApi } from './useApi';
import { useNotification } from './useNotification';

type CreateShopProps = {
  title: string;
  description: string;
  subscriptions: Subscription[];
};

export const useCreateShop = ({ title, description, subscriptions }: CreateShopProps) => {
  const [shopId, setShopId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { address: contractAddress, abi } = useContractAbi({ contract: ContractsEnum.Merchants });

  const publicClient = usePublicClient();
  const { data: walletClient } = useWalletClient();

  const { createShop, createShopSubscription, updateShop, updateShopSubscription } = useApi();
  const { address: ownerAddress, isConnected } = useAccount();
  const { success, handleError } = useNotification();

  const startCreateShop = useCallback(async () => {
    if (isConnected && ownerAddress && walletClient && subscriptions.length) {
      try {
        setIsLoading(true);

        const { request, result: nextMerchantId } = await publicClient.simulateContract({
          address: contractAddress,
          abi,
          functionName: 'createMerchant',
          account: ownerAddress,
          args: [],
        });

        const createMerchantHash = await walletClient?.writeContract(request);

        await publicClient.waitForTransactionReceipt({ hash: createMerchantHash });

        success({
          title: 'Success',
          description: `Shop "${title}" created`,
          txHash: createMerchantHash,
        });

        const createShopData = await createShop({
          name: title,
          description,
          chain: 'ETHEREUM',
          owner: ownerAddress || '',
        });
        const _shopId = createShopData?.data.id;
        setShopId(_shopId);

        console.log('shopId', _shopId);

        await updateShop(_shopId, {
          shopContractId: parseInt((nextMerchantId as unknown as BigInt)?.toString()),
        });

        for (let i = 0; i < subscriptions.length; i++) {
          const subscription = subscriptions[i];
          const period = subscription.period.toUpperCase().replace(' ', '_');

          const { request: subRequest, result: nextSubId } = await publicClient.simulateContract({
            address: contractAddress,
            abi,
            functionName: 'createSubscription',
            account: ownerAddress,
            args: [nextMerchantId, subscription.price],
          });

          console.log('nextSubId', nextSubId);

          const createSubscriptionHash = await walletClient?.writeContract(subRequest);
          await publicClient.waitForTransactionReceipt({ hash: createSubscriptionHash });
          success({
            title: 'Success',
            description: `${subscription.period} subscription for ${subscription.price}$ created`,
            txHash: createSubscriptionHash,
          });

          const createSubscriptionData = await createShopSubscription(_shopId, {
            price: subscription.price,
            period,
            description: subscription.description,
          });
          const _subsId = createSubscriptionData?.data.id;
          await updateShopSubscription(_shopId, _subsId, {
            subscriptionContractId: parseInt((nextSubId as unknown as BigInt)?.toString()) + i,
          });
        }

        success({ title: 'Shop created', description: 'Shop creation has been finished' });

        return _shopId;
      } catch (e) {
        handleError(e);
      } finally {
        setIsLoading(false);
      }
    }
  }, [
    publicClient,
    walletClient,
    isConnected,
    ownerAddress,
    handleError,
    abi,
    contractAddress,
    createShop,
    updateShop,
    description,
    success,
    title,
    subscriptions,
    createShopSubscription,
    updateShopSubscription,
  ]);

  return { shopId, startCreateShop, isLoading };
};
