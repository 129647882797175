import { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

import WebLogo from '@/assets/images/icons/web.svg';
import ZapierLogo from '@/assets/images/icons/zapier.svg';

export type Integration = {
  id: number;
  title: string;
  logo: string;
  value?: string;
};
type IntegrationsModalProps = {
  integrations: Integration[];
  onSave: (integrations: Integration[]) => void;
  onClose: () => void;
};

type IntegrationOption = Integration & { placeholder: string };
const INTEGRATION_TYPES: IntegrationOption[] = [
  {
    id: 1,
    title: 'Zapier',
    logo: ZapierLogo,
    placeholder: 'Your ID',
  },
  {
    id: 2,
    title: 'Webhook',
    logo: WebLogo,
    placeholder: 'POST: yourserver.com/api/...',
  },
];

export const IntegrationsModal = ({ integrations, onSave, onClose }: IntegrationsModalProps) => {
  const [localIntegrations, setLocalIntegrations] = useState(INTEGRATION_TYPES);

  useEffect(() => {
    setLocalIntegrations((li) =>
      li.map((integration) => {
        const propIntegration = integrations.find((i) => i.id === integration.id);
        if (propIntegration) {
          return { ...integration, value: propIntegration.value };
        }
        return integration;
      })
    );
  }, [integrations]);

  const handleIntegrationUpdate = useCallback(
    (id: number, value: string) => {
      let newIntegrations = localIntegrations.map((integration) =>
        integration.id === id ? { ...integration, value } : integration
      );

      if (!newIntegrations.find((integration) => integration.id === id)) {
        let integration =
          INTEGRATION_TYPES.find((integration) => integration.id === id) ||
          ({} as IntegrationOption);
        integration = { ...integration, value };

        newIntegrations = [...newIntegrations, integration];
      }
      setLocalIntegrations(newIntegrations);
    },
    [localIntegrations]
  );
  const handleSave = useCallback(() => {
    const items = localIntegrations
      .filter((integration) => integration.value)
      .map(({ placeholder, ...params }) => params);
    onSave(items);
  }, [onSave, localIntegrations]);

  return (
    <Modal isCentered isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontWeight="600" fontSize={26}>
          Add integrations
        </ModalHeader>
        <ModalBody>
          <Flex gap="12px" flexDirection="column">
            {localIntegrations.map((integration) => (
              <IntegrationRow
                key={integration.id}
                {...integration}
                onChange={(value) => handleIntegrationUpdate(integration.id, value)}
              />
            ))}
          </Flex>
          <Text color="gray" fontSize="20px" mt="24px">
            More integrations will be added later...
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button width="100%" onClick={handleSave}>
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

type IntegrationRowProps = IntegrationOption & { onChange: (value: string) => void };
const IntegrationRow = ({ title, logo, value, placeholder, onChange }: IntegrationRowProps) => {
  const handleChange = useCallback(
    (event: any) => {
      const val = event.target.value || undefined;
      onChange(val);
    },
    [onChange]
  );

  return (
    <Flex flexDirection="column" gap="8px">
      <Flex gap="12px" alignItems="center">
        <Box width="36px" height="36px" flexShrink="0">
          <img src={logo} alt="integration logo" />
        </Box>
        <Text width="110px">{title}</Text>
        <Input value={value} onChange={handleChange} placeholder={placeholder} />
      </Flex>
    </Flex>
  );
};
