import { Chain } from 'wagmi';
import { avalancheFuji, bscTestnet, polygonMumbai } from 'wagmi/chains';

export const getExplorerLink = (chain?: Chain, hash?: string, isAddress?: boolean) => {
  if (!hash) return '';

  const type = isAddress ? 'address' : 'tx';

  if (chain?.id === avalancheFuji.id) {
    return `https://testnet.snowtrace.io/${type}/${hash}`;
  }
  if (chain?.id === bscTestnet.id) {
    return `https://testnet.bscscan.com/${type}/${hash}`;
  }
  if (chain?.id === polygonMumbai.id) {
    return `https://mumbai.polygonscan.com/${type}/${hash}`;
  }

  return `https://mumbai.polygonscan.com/${type}/${hash}`;
};
