import { RouterProvider } from 'react-router-dom';
import { ChakraProvider, Flex } from '@chakra-ui/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { WagmiConfig } from 'wagmi';

import { theme } from '@/modules/chakra';
import { config } from '@/modules/wagmi';

import { queryClient } from './modules/query';
import { router } from './router';

function App() {
  return (
    <WagmiConfig config={config}>
      <ChakraProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <div className="app">
            <Flex position="relative" minHeight="100vh" direction="column" flexGrow={1}>
              <RouterProvider router={router} />
            </Flex>
          </div>
        </QueryClientProvider>
      </ChakraProvider>
    </WagmiConfig>
  );
}

export default App;
