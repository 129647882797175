import { useMemo, useState } from 'react';
import { Box, Flex, Grid, GridItem, Text } from '@chakra-ui/react';
import { generateFromString } from 'generate-avatar';

import ArchivedIcon from '@/assets/images/icons/archived.svg';
import OkIcon from '@/assets/images/icons/ok.svg';
import { CenteredSpinner } from '@/components/common/CenteredSpinner/CenteredSpinner';
import { useMySubscriptions } from '@/hooks/useSubscriptions';

export const MySubscriptions = () => {
  const [type, setType] = useState('active');

  const { mySubscriptions, mySubscriptionsRequest } = useMySubscriptions();

  const subscriptionsList = useMemo(() => {
    if (type === 'active') return mySubscriptions;
    return [];
  }, [type, mySubscriptions]);

  return (
    <Box>
      <Flex>
        {mySubscriptionsRequest.isLoading ? <CenteredSpinner /> : null}

        <Text
          color={type === 'active' ? 'black' : 'gray.400'}
          textStyle="h2"
          onClick={() => setType('active')}
          cursor="pointer"
        >
          my subscriptions
        </Text>
        <Text
          ml="20px"
          color={type === 'archived' ? 'black' : 'gray.400'}
          textStyle="h2"
          onClick={() => setType('archived')}
          cursor="pointer"
        >
          archived
        </Text>
      </Flex>
      <Grid mt="20px" templateColumns="2fr 2fr 2fr" gap="10px">
        {subscriptionsList.map((subscription: any) => (
          <GridItem key={subscription.id} colSpan={1}>
            <SubscriptionCard
              shopId={subscription.shopData.id}
              shopName={subscription.shopData.name}
              network={'POLYGON'}
              price={subscription.price}
              description={subscription.description}
              isActive={type === 'active'}
            />
          </GridItem>
        ))}
      </Grid>
    </Box>
  );
};

type SubscriptionCardProps = {
  shopId: string;
  shopName: string;
  network: string;
  price: number;
  description: string;
  isActive: boolean;
};
const SubscriptionCard = ({
  shopId,
  shopName,
  network,
  isActive,
  price,
  description,
}: SubscriptionCardProps) => {
  return (
    <Box border="1px solid #DEDEDE" borderRadius="12px" padding="20px" width="100%">
      <Flex flexDirection="column" justifyContent="space-between" height="100%">
        <Flex gap="16px" alignItems="flex-start">
          <Box
            borderRadius="50%"
            width="50px"
            height="50px"
            bgColor="gray"
            overflow="hidden"
            flexShrink={0}
          >
            {shopId ? (
              <img src={`data:image/svg+xml;utf8,${generateFromString(shopId)}`} alt="logo" />
            ) : null}
          </Box>
          <Flex gap="4px" justifyContent="space-between" alignItems="flex-start" direction="column">
            <Text fontSize="20px" fontWeight="600" whiteSpace="nowrap">
              {shopName}
            </Text>
            <Box border="1px solid #DEDEDE" borderRadius="16px" padding="0 12px" fontSize="12px">
              {network}
            </Box>
          </Flex>
          <Flex
            alignItems="center"
            ml="auto"
            padding="4px 8px"
            border="1px solid #DEDEDE"
            borderRadius="24px"
          >
            <Box width="20px" mr="6px">
              <img src={isActive ? OkIcon : ArchivedIcon} alt="" />
            </Box>
            {isActive ? 'Subscribed' : 'Archived'}
          </Flex>
        </Flex>

        <Flex mt="35px">
          <Text fontSize="32px" fontWeight="500">
            $
          </Text>
          <Text fontSize="54px" fontWeight="500" ml="4px">
            {price}
          </Text>
        </Flex>
        <Text fontSize="16px" fontWeight="500" color="gray">
          per month
        </Text>

        <Text mt="35px" fontSize="16px">
          {description}
        </Text>
      </Flex>
    </Box>
  );
};
