import { useCallback } from 'react';
import { useLocation, useOutlet, useSearchParams } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { Box, Flex } from '@chakra-ui/react';

import { Header } from '@/components/Header/Header';
import { routes } from '@/router';

export const Layout = () => {
  const location = useLocation();
  const currentOutlet = useOutlet();

  const { nodeRef } =
    routes.find((route) =>
      // Hack for '/raffles/:id' route
      route.path.includes(location.pathname.split('/')[1])
    ) ?? {};

  const scrollToTop = useCallback(() => {
    window?.scroll(0, 0);
  }, []);

  return (
    <>
      <Header />
      <SwitchTransition>
        <CSSTransition
          key={location.pathname}
          nodeRef={nodeRef as any}
          timeout={250}
          classNames="fade-transition"
          onEntering={scrollToTop}
          unmountOnExit
          in
          appear
        >
          {(state) => (
            <Flex
              ref={nodeRef as any}
              flexGrow={1}
              direction="column"
              width="100%"
              maxWidth="1320px"
              padding="0 32px 64px"
              margin="32px auto 0"
            >
              {currentOutlet}
            </Flex>
          )}
        </CSSTransition>
      </SwitchTransition>
    </>
  );
};
