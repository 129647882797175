import { useCallback, useState } from 'react';
import {
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
} from '@chakra-ui/react';

export type Subscription = {
  id: string;
  period: string;
  price: number;
  description: string;
};
type SubscriptionModalProps = {
  onSave: (sub: Subscription) => void;
  onClose: () => void;
};
export const SubscriptionModal = ({ onClose, onSave }: SubscriptionModalProps) => {
  const [price, setPrice] = useState(0);
  const [description, setDescription] = useState('');
  const [period, setPeriod] = useState('');
  const handleSave = useCallback(() => {
    if (price && description && period) {
      onSave({
        id: '0',
        price,
        description,
        period,
      });
    }
  }, [onSave, price, description, period]);

  return (
    <Modal isCentered isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontWeight="600" fontSize={26}>
          Add Subscription
        </ModalHeader>
        <ModalBody>
          <Flex flexDirection="column" mt="16px" gap="8px">
            <Text>Price</Text>
            <Input type="number" onChange={(e) => setPrice(parseFloat(e.target.value))} />
          </Flex>

          <Flex flexDirection="column" mt="16px" gap="8px">
            <Text>Period</Text>
            <Select
              placeholder="Select period"
              onChange={(option) => setPeriod(option.target.value)}
            >
              <option value="Monthly">Monthly</option>
              <option value="Half year">Half year</option>
              <option value="Yearly">Yearly</option>
            </Select>
          </Flex>

          <Flex flexDirection="column" mt="16px" gap="8px">
            <Text>Description</Text>
            <Input onChange={(e) => setDescription(e.target.value)} />
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button width="100%" onClick={handleSave}>
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
