import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CheckIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { generateFromString } from 'generate-avatar';
import { useAccount } from 'wagmi';

import { CenteredSpinner } from '@/components/common/CenteredSpinner/CenteredSpinner';
import { useShopById, useSubscription } from '@/hooks/useShops';
import { useMySubscriptions, useSubscribe } from '@/hooks/useSubscriptions';

import { ConnectWalletButton } from '../common/ConnectWalletButton/ConnectWalletButton';
import { Subscription } from '../CreateShop/SubscriptionModal';

export const SubscriptionPage = () => {
  const { shopId, subId } = useParams();
  const { subscriptionRequest, subscription } = useSubscription(shopId || '', subId || '');
  const { shopData, shopRequest } = useShopById(shopId || '');
  const { subscribe } = useSubscribe();

  // 5ca4b11b-ac3b-4e61-b114-e7fd08396996
  // 5d603a1c-590e-4313-a9e0-a76567f8ced7

  return (
    <Box width="100%" maxWidth="640px" m="0 auto">
      {subscriptionRequest.isLoading || shopRequest.isLoading ? (
        <CenteredSpinner />
      ) : (
        <>
          <Flex gap="16px" alignItems="center" mb="24px">
            <Box borderRadius="50%" width="50px" height="50px" bgColor="gray" overflow="hidden">
              <img src={`data:image/svg+xml;utf8,${generateFromString(shopId || '')}`} alt="logo" />
            </Box>
            <Flex
              gap="4px"
              justifyContent="space-between"
              alignItems="flex-start"
              direction="column"
            >
              <Text fontSize="20px" fontWeight="600">
                {shopData.name}
              </Text>
              <Box border="1px solid #DEDEDE" borderRadius="16px" padding="0 12px" fontSize="12px">
                {'POLYGON'}
              </Box>
            </Flex>
          </Flex>

          <SubscribePlanCard
            id={subscription.id}
            period={subscription.period.toLowerCase()}
            price={subscription.price}
            description={subscription.description}
            subscriptionContractId={subscription.subscriptionContractId}
            onSubscribe={() =>
              subscribe(subscription.price, subscription.subscriptionContractId, subscription.id)
            }
          />
        </>
      )}
    </Box>
  );
};

type SubscribePlanCardProps = Subscription & {
  subscriptionContractId: number;
  onSubscribe: () => Promise<void>;
};
const SubscribePlanCard = ({
  period,
  price,
  subscriptionContractId,
  description,
  onSubscribe,
}: SubscribePlanCardProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { isConnected } = useAccount();
  const navigate = useNavigate();

  const { mySubscriptions } = useMySubscriptions();

  const isSubscribed = useMemo(() => {
    return mySubscriptions.find(
      (sub: any) => sub.subscriptionContractId === subscriptionContractId
    );
  }, [mySubscriptions, subscriptionContractId]);

  const handleSubscribe = useCallback(() => {
    setIsLoading(true);
    onSubscribe()
      .then(() =>
        setTimeout(() => {
          navigate('/my-subs');
        }, 3000)
      )
      .finally(() => setIsLoading(false));
  }, [onSubscribe, navigate]);

  return (
    <Flex
      border="1px solid #DEDEDE"
      borderRadius="12px"
      padding="20px"
      flexDirection="column"
      width="100%"
    >
      <Flex justifyContent="space-between">
        <Text fontSize="32px" fontWeight="600" textTransform="capitalize">
          {period}
        </Text>
      </Flex>
      <Flex mt="36px">
        <Text fontSize="40px" fontWeight="500">
          $
        </Text>
        <Text fontSize="72px" fontWeight="500" ml="4px">
          {price}
        </Text>
      </Flex>
      <Text fontSize="24px" fontWeight="500" color="gray">
        per month
      </Text>
      <Text fontSize="24px" fontWeight="500" mt="36px">
        {description}
      </Text>

      {/* <Flex alignItems="center" mt="14px">
        <CheckIcon />
        <Text ml="12px">Over 2000+ templates</Text>
      </Flex>
      <Flex mt="8px" alignItems="center">
        <CheckIcon />
        <Text ml="12px">All stuff included</Text>
      </Flex>
      <Flex mt="8px" alignItems="center">
        <CheckIcon />
        <Text ml="12px">Coolest UI ever</Text>
      </Flex>
      <Flex mt="8px" alignItems="center">
        <CheckIcon />
        <Text ml="12px">Some cool shit as well</Text>
      </Flex> */}

      <Box mt="36px" width="100%">
        {isConnected ? (
          isSubscribed ? (
            <Box
              textAlign="center"
              padding="12px"
              border="1px solid white"
              borderRadius="12px"
              fontWeight="500"
              textTransform="uppercase"
            >
              Subscription active
            </Box>
          ) : (
            <Button onClick={handleSubscribe} width="100%" isLoading={isLoading}>
              Subscribe
            </Button>
          )
        ) : (
          <ConnectWalletButton width="100%" />
        )}
      </Box>
    </Flex>
  );
};
