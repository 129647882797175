import React, { createRef } from 'react';
import { createBrowserRouter, Navigate, useRouteError } from 'react-router-dom';

import { Dashboard } from '@/components/Dashboard/Dashboard';
import { Layout } from '@/components/Layout/Layout';
import { Shop } from '@/components/Shop/Shop';

import { Balance } from './components/Balance/Balance';
import { CreateShop } from './components/CreateShop/CreateShop';
import { MyShops } from './components/MyShops/MyShops';
import { MySubscriptions } from './components/MySubscriptions/MySubscriptions';
import { Shops } from './components/Shops/Shops';
import { SubscriptionPage } from './components/SubscriptionPage/SubscriptionPage';

export const isDevelopment = process.env.NODE_ENV === 'development';

export const LANDING_PATH = '/';
export const AVATARS_LANDING_PATH = '/';

const appRoutes = [
  { path: '/', name: 'Shops', element: <Shops />, nodeRef: createRef() },
  { path: '/dashboard', name: 'Dashboard', element: <Dashboard />, nodeRef: createRef() },
  { path: '/my-shops', name: 'My Shops', element: <MyShops />, nodeRef: createRef() },
  {
    path: '/my-subs',
    name: 'My Subscriptions',
    element: <MySubscriptions />,
    nodeRef: createRef(),
  },
  { path: '/shop/:id', name: 'Shop', element: <Shop />, nodeRef: createRef() },
  {
    path: '/shop/:shopId/sub/:subId',
    name: 'Subscription',
    element: <SubscriptionPage />,
    nodeRef: createRef(),
  },
  { path: '/balance', name: 'Balance', element: <Balance />, nodeRef: createRef() },
  { path: '/create', name: 'Create Shop', element: <CreateShop />, nodeRef: createRef() },
];

export const routes = appRoutes;

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: routes.map((route) => ({
      index: route.path === '/',
      path: route.path === '/' ? undefined : route.path,
      element: route.element,
    })),
    errorElement: <ErrorBoundary />,
  },
  {
    path: '*',
    element: <Navigate to="/" />,
  },
]);

function ErrorBoundary() {
  let error = useRouteError();
  console.error(error);
  // Uncaught ReferenceError: path is not defined
  return <div>Error has been occured. Please reload the page.</div>;
}
