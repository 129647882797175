import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SettingsIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useClipboard,
} from '@chakra-ui/react';
import { generateFromString } from 'generate-avatar';
import { useAccount } from 'wagmi';

import { CenteredSpinner } from '@/components/common/CenteredSpinner/CenteredSpinner';
import { useNotification } from '@/hooks/useNotification';
import { useShopById } from '@/hooks/useShops';
import { useMySubscriptions, useSubscribe } from '@/hooks/useSubscriptions';

import { ConnectWalletButton } from '../common/ConnectWalletButton/ConnectWalletButton';
import { Subscription } from '../CreateShop/SubscriptionModal';

export const Shop = () => {
  const { id } = useParams();
  const { address } = useAccount();
  const { subscribe } = useSubscribe();

  const { shopData, shopSubscriptions, shopRequest } = useShopById(id || '');

  const network = 'POLYGON';

  const isOwner = shopData.ownerAddress === address;

  return (
    <Box mt="40px">
      {shopRequest.isLoading ? (
        <CenteredSpinner />
      ) : (
        <>
          <ShopHeader
            id={shopData.id}
            title={shopData.name}
            description={shopData.description}
            isOwner={isOwner}
          />
          <Text mt="52px" textStyle="h3" textAlign="center">
            Our plans
          </Text>
          <Grid mt="24px" templateColumns="1fr 1fr 1fr" gap="24px">
            {shopSubscriptions.map((sub: any) => (
              <GridItem key={sub.id} colSpan={1}>
                <SubscribePlanCard
                  {...sub}
                  withShareButton={isOwner}
                  shopId={shopData.id}
                  period={sub.period.toLowerCase()}
                  network={network}
                  subscriptionContractId={sub.subscriptionContractId}
                  onSubscribe={() => subscribe(sub.price, sub.subscriptionContractId, sub.id)}
                />
              </GridItem>
            ))}
          </Grid>
        </>
      )}
    </Box>
  );
};

type ShopHeaderProps = {
  id: string;
  title: string;
  logo?: string;
  description: string;
  isOwner: boolean;
};
const ShopHeader = ({ id, title, logo, description, isOwner }: ShopHeaderProps) => {
  const navigate = useNavigate();
  const handleEdit = useCallback(() => {
    navigate('/create');
  }, [navigate]);

  return (
    <Flex flexDir="column" alignItems="center">
      <Box width="100px" borderRadius="50%" overflow="hidden">
        {id ? <img src={`data:image/svg+xml;utf8,${generateFromString(id)}`} alt="logo" /> : null}
      </Box>
      <Flex alignItems="center" justifyContent="center" mt="18px" flexDirection="column">
        {/* {isOwner ? (
          <Button
            aria-label="settings"
            rightIcon={<SettingsIcon />}
            bgColor="transparent"
            color="gray.400"
            border="none"
            height="unset"
            onClick={handleEdit}
          >
            Edit
          </Button>
        ) : null} */}
        <Text textStyle="h3">{title}</Text>
      </Flex>
      <Text textStyle="h3" color="gray">
        {description}
      </Text>
    </Flex>
  );
};

type SubscribePlanCardProps = Subscription & {
  network: string;
  shopId: string;
  withShareButton?: boolean;
  subscriptionContractId: number;
  onSubscribe: () => Promise<void>;
};
const SubscribePlanCard = ({
  id,
  shopId,
  period,
  price,
  description,
  network,
  withShareButton,
  subscriptionContractId,
  onSubscribe,
}: SubscribePlanCardProps) => {
  const { isConnected } = useAccount();
  const navigate = useNavigate();
  const { mySubscriptions } = useMySubscriptions();
  const { success } = useNotification();
  const link = useMemo(() => {
    return `/shop/${shopId}/sub/${id}`;
  }, [shopId, id]);

  const isSubscribed = useMemo(() => {
    return mySubscriptions.find(
      (sub: any) => sub.subscriptionContractId === subscriptionContractId
    );
  }, [mySubscriptions, subscriptionContractId]);

  const [isLoading, setIsLoading] = useState(false);

  const handleSubscribe = useCallback(() => {
    setIsLoading(true);
    onSubscribe()
      .then(() =>
        setTimeout(() => {
          navigate('/my-subs');
        }, 3000)
      )
      .finally(() => setIsLoading(false));
  }, [onSubscribe, navigate]);

  const { onCopy } = useClipboard('app.subscrypt.org' + link);

  const handleShare = useCallback(() => {
    onCopy();
    success({ title: 'Copied' });
  }, [onCopy, success]);
  const redirectToSub = useCallback(() => {
    navigate(link);
  }, [link, navigate]);

  return (
    <Flex
      border="1px solid #DEDEDE"
      borderRadius="12px"
      padding="16px"
      flexDirection="column"
      width="100%"
    >
      <Flex justifyContent="space-between">
        <Text fontSize="18px" fontWeight="500" textTransform="capitalize">
          {period.replace('_', '-')}
        </Text>

        <Flex>
          {withShareButton ? (
            <Menu>
              <MenuButton
                as={IconButton}
                bgColor="transparent"
                icon={<SettingsIcon />}
                color="gray.400"
                border="none"
                height="unset"
              />
              <MenuList>
                <MenuItem onClick={handleShare}>Copy share link</MenuItem>
                <MenuItem onClick={redirectToSub}>Open subscription page</MenuItem>
              </MenuList>
            </Menu>
          ) : null}

          <Box
            padding="2px 12px"
            border="1px solid #DEDEDE"
            borderRadius="24px"
            fontSize="16px"
            fontWeight="500"
          >
            {network}
          </Box>
        </Flex>
      </Flex>
      <Flex mt="8px">
        <Text fontSize="32px" fontWeight="500">
          $
        </Text>
        <Text fontSize="54px" fontWeight="500" ml="4px">
          {price}
        </Text>
      </Flex>
      <Text fontSize="16px" fontWeight="500" color="gray">
        per month
      </Text>
      <Text fontSize="16px" fontWeight="500" mt="16px">
        {description}
      </Text>

      {/* <Box width="100%" height="1px" bgColor="gray.400" my="16px"></Box> */}

      {/* <Flex alignItems="center">
        <CheckIcon />
        <Text ml="12px">Over 2000+ templates</Text>
      </Flex>
      <Flex mt="8px" alignItems="center">
        <CheckIcon />
        <Text ml="12px">All stuff included</Text>
      </Flex>
      <Flex mt="8px" alignItems="center">
        <CheckIcon />
        <Text ml="12px">Coolest UI ever</Text>
      </Flex>
      <Flex mt="8px" alignItems="center">
        <CheckIcon />
        <Text ml="12px">Some cool shit as well</Text>
      </Flex> */}
      <Box mt="24px">
        {isConnected ? (
          isSubscribed ? (
            <Box
              textAlign="center"
              padding="12px"
              border="1px solid white"
              borderRadius="12px"
              fontWeight="500"
              textTransform="uppercase"
            >
              Subscription active
            </Box>
          ) : (
            <Button width="100%" isLoading={isLoading} onClick={handleSubscribe}>
              Subscribe
            </Button>
          )
        ) : (
          <ConnectWalletButton width="100%" />
        )}
      </Box>
    </Flex>
  );
};
