import { Chain, useChainId, useNetwork } from 'wagmi';

import Contracts from './config.json';

export enum ContractsEnum {
  Merchants = 'SubsCryptMerchants',
  Accounts = 'SubsCryptAccounts',
  Payments = 'SubsCryptPayments',
}

type ContractAbi = {
  address: `0x${string}`;
  abi: any;
};

enum ChainIDs {
  //   sepolia = 11155111,
  //   polygonZkEvmTestnet = 1442,
  mumbai = 80001,
  bscTestnet = 97,
  avalancheFuji = 43113,
}

export const useContractAbi = ({
  contract,
}: {
  contract: ContractsEnum;
}): ContractAbi & { chain: Chain } => {
  const { chain } = useNetwork();
  let chainId = useChainId();

  if (chain?.unsupported) {
    chainId = ChainIDs.mumbai;
  }

  if (contract === ContractsEnum.Merchants) {
    // Merchants контракт только в сети mumbai
    chainId = ChainIDs.mumbai;
  }

  const contractData = (Contracts as any)[chainId][0].contracts[contract];

  return { ...contractData, chain };
};
