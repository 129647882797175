import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Flex, Text } from '@chakra-ui/react';
import { useAccount, useDisconnect, useNetwork } from 'wagmi';

import { ConnectWalletButton } from '@/components/common/ConnectWalletButton/ConnectWalletButton';
import AlexShopLogo from '@/components/Dashboard/images/alex-shop.png';
import { useAccountBalance } from '@/hooks/useSubscriptions';
import { useSwitchNetworkToSupported } from '@/hooks/useSwitchNetworkToSupported';
import { trimAddress } from '@/utils/address';

export function Profile() {
  const { address, isConnected } = useAccount();
  const navigate = useNavigate();
  const network = useNetwork();

  const { disconnect } = useDisconnect();

  const handleDisconnect = useCallback(() => {
    disconnect();
    navigate('/');
  }, [disconnect, navigate]);

  useSwitchNetworkToSupported();

  const { balanceEth } = useAccountBalance();

  if (!isConnected) {
    return <ConnectWalletButton />;
  }

  return (
    <Flex alignItems="center" justifyContent="center">
      <Box
        width="45px"
        height="45px"
        border="1px solid gray"
        borderRadius="50%"
        onClick={handleDisconnect}
        cursor="pointer"
      >
        <img src={AlexShopLogo} alt="" />
      </Box>
      <Box ml="10px">
        <Text fontSize="18px">{address ? trimAddress(address) : 'Address'}</Text>
        <Text fontSize="14px">
          {balanceEth.toString()} {network.chain?.nativeCurrency.symbol} | {network.chain?.name}
        </Text>
      </Box>
    </Flex>
  );
}
