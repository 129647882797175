import { extendTheme } from '@chakra-ui/react';

import { buttonTheme } from './button';

const breakpoints = {
  sm: '1px',
  md: '480px',
  lg: '640px',
  xl: '1024px',
  '2xl': '1600px',
};

const colors = {
  green: '#14AE5C',
  red: '#E84142',
  gray: {
    100: '#DEDEDE',
    400: '#969696',
  },
};

const semanticTokens = {
  colors: {
    success: 'green',
    error: 'red',
    // gray: 'gray.100',
    // error: 'red',
    // primary: 'green.400',
    // secondary: 'green.100',
  },
  sizes: {},
  fonts: {},
};

const textStyles = {
  h2: {
    fontFamily: 'Inter',
    fontSize: '32px',
    fontWeight: '600',
    lineHeight: '130%',
    letterSpacing: '-0.022em',
  },
  h3: {
    fontSize: '24px',
    fontWeight: '600',
    lineHeight: '36px',
    letterSpacing: '-2.2%',
  },

  text1: {
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '130%',
  },
  boldSmall: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '600',
  },
  textSmall: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '400',
  },
  button: {
    fontSize: ['16px'],
    fontWeight: '600',
    lineHeight: '20px',
    textTransform: 'uppercase',
  },
  menuDefault: {
    fontSize: ['26px'],
    fontWeight: '600',
    lineHeight: '130%',
  },
};

// border radius
const radii = {
  sm: '5px',
  md: '10px',
};

const styles = {
  global: {
    body: {
      // color: 'white',
    },
  },
};

const shadows = {};

export const theme = extendTheme({
  config: {
    useSystemColorMode: false,
  },
  shadows,
  components: {
    Button: buttonTheme,
    IconButton: buttonTheme,
  },
  breakpoints,
  semanticTokens,
  colors,
  textStyles,
  radii,
  styles,
});
