import { useMemo } from 'react';
import { ArrowForwardIcon, DownloadIcon } from '@chakra-ui/icons';
import { Avatar, Box, Flex, Text } from '@chakra-ui/react';
import AVAXIcon from 'cryptocurrency-icons/svg/color/avax.svg';
import BNBIcon from 'cryptocurrency-icons/svg/color/bnb.svg';
import MATICIcon from 'cryptocurrency-icons/svg/color/matic.svg';
import { useAccount, useNetwork } from 'wagmi';

import { useAccountBalance } from '@/hooks/useSubscriptions';
import { trimAddress } from '@/utils/address';

export const Balance = () => {
  const { address } = useAccount();
  const network = useNetwork();

  const icon = useMemo(() => {
    if (network.chain?.id === 97) return BNBIcon;
    if (network.chain?.id === 43113) return AVAXIcon;
    return MATICIcon;
  }, [network]);

  const { balanceEth } = useAccountBalance();

  return (
    <Box>
      <Text textStyle="h2">Balance</Text>

      <Box mt="40px" border="1px solid #DEDEDE" borderRadius="24px" padding="12px" width="600px">
        <Flex bgColor="#DEDEDE4D" border="1px solid #D4D4D4" borderRadius="12px" padding="12px">
          <Avatar width="32px" height="32px" name="MATIC" src={icon} bgColor="gray" />
          <Text ml="10px" fontSize="20px" fontWeight="500">
            {trimAddress(address || '')}
          </Text>
        </Flex>

        <Box pl="8px">
          <Text fontSize="24px" fontWeight="500" mt="30px">
            Balance
          </Text>
          <Text fontSize="48px" fontWeight="500" mt="4px">
            {balanceEth.toString()} {network.chain?.nativeCurrency.symbol}
          </Text>
          {/* <Text color="gray.400" fontSize="24px" fontWeight="500" mt="4px">
            45,056 USD
          </Text> */}

          <Flex mt="48px">
            <Flex flexDirection="column" alignItems="center">
              <Flex
                borderRadius="50%"
                width="60px"
                height="60px"
                alignItems="center"
                justifyContent="center"
                border="1px solid #E2E2E2"
                transition="all 0.2s"
                _hover={{
                  cursor: 'pointer',
                  borderColor: 'black',
                }}
              >
                <ArrowForwardIcon fontSize="24px" transform="rotate(-45deg)" />
              </Flex>
              <Text mt="14px">Deposit</Text>
            </Flex>
            <Flex flexDirection="column" alignItems="center" ml="16px">
              <Flex
                borderRadius="50%"
                width="60px"
                height="60px"
                alignItems="center"
                justifyContent="center"
                border="1px solid #E2E2E2"
                transition="all 0.2s"
                _hover={{
                  cursor: 'pointer',
                  borderColor: 'black',
                }}
              >
                <DownloadIcon fontSize="18px" />
              </Flex>
              <Text mt="14px">Withdraw</Text>
            </Flex>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};
