import client from '@/modules/api';

type CreateShopProps = {
  name: string;
  description: string;
  chain: string;
  owner: string;
};
type UpdateShopProps = {
  name?: string;
  description?: string;
  chain?: string;
  shopContractId: number;
};
type CreateSubscriptionProps = {
  price: number;
  period: string;
  description: string;
};
type UpdateSubscriptionProps = {
  price?: number;
  period?: string;
  description?: string;
  subscriptionContractId: number;
};

export const useApi = () => {
  const getShops = async (page: number, limit: number) => {
    return client.get('/shops', { params: { page, limit } });
  };
  const createShop = async (props: CreateShopProps) => {
    return client.post('/shops', props);
  };
  const updateShop = async (id: string, props: UpdateShopProps) => {
    return client.patch(`/shops/shop/${id}`, props);
  };
  const getShopById = async (id: string) => {
    return client.get(`/shops/shop/${id}`);
  };
  const getShopsByOwner = async (owner: string) => {
    return client.get(`/shops/my/${owner}`);
  };

  const getShopSubscriptionById = async (shopId: string, subscriptionId: string) => {
    return client.get(`/shops/shop/${shopId}/subscription/${subscriptionId}`);
  };
  const createShopSubscription = async (shopId: string, props: CreateSubscriptionProps) => {
    return client.post(`/shops/shop/${shopId}/subscription`, props);
  };
  const updateShopSubscription = async (
    shopId: string,
    subscriptionId: string,
    props: UpdateSubscriptionProps
  ) => {
    return client.patch(`/shops/shop/${shopId}/subscription/${subscriptionId}`, props);
  };
  // Subscriptions
  const getWalletSubscriptions = async (wallet: string) => {
    return client.get(`/subscriptions/wallet/${wallet}`);
  };
  const createWalletSubscription = async (wallet: string, subscriptionId: string) => {
    return client.post(`/subscriptions/wallet/${wallet}/${subscriptionId}`);
  };

  return {
    client,
    getShops,
    createShop,
    updateShop,
    getShopById,
    getShopsByOwner,
    getShopSubscriptionById,
    createShopSubscription,
    updateShopSubscription,
    getWalletSubscriptions,
    createWalletSubscription,
  };
};
