import { Avatar, Box, Flex, Grid, GridItem, Text } from '@chakra-ui/react';
import MATICIcon from 'cryptocurrency-icons/svg/color/matic.svg';

import GrowIcon from '@/assets/images/icons/grow.svg';

import AlexShopLogo from './images/alex-shop.png';
import AzukiShopLogo from './images/azuki-shop.png';
import ChartBig from './images/chart-big.png';
import ChartSmall from './images/chart-small.png';
import MeowShopLogo from './images/meow-shop.png';

const cardProps = {
  border: '1px solid gray',
  borderRadius: '16px',
  padding: '20px',
};
const titleProps = {
  fontWeight: '500',
  fontSize: '24px',
};
const filterProps = {
  fontSize: '20px',
  fontWeight: '500',
  padding: '4px 16px',
  display: 'flex',
  justifyContent: 'center',
  borderRadius: '24px',
  _hover: {
    cursor: 'pointer',
  },
};

const shopHistory = [
  {
    title: '0x91A7668..',
    description: 'Owner',
    logo: AlexShopLogo,
    change: '+6 MATIC',
    isGrow: true,
  },
  {
    title: '0x01C7656..',
    description: '37 USD / 41 MATIC per month',
    logo: AzukiShopLogo,
    change: '-6 MATIC',
    isGrow: false,
  },
  {
    title: '0x11U9654..',
    description: '37 USD / 41 MATIC per month',
    logo: MeowShopLogo,
    change: '-12 MATIC',
    isGrow: false,
  },
];
export const Dashboard = () => {
  return (
    <Box>
      <Text textStyle="h2">Dashboard</Text>
      <Grid
        mt="20px"
        height="760px"
        templateColumns="1fr 1fr 1fr"
        gap="12px"
        templateRows="2fr 1fr"
      >
        <GridItem colSpan={2} {...cardProps} height="100%">
          <Flex alignItems="flex-start" justifyContent="space-between">
            <Flex flexDirection="column">
              <Text {...titleProps} fontSize="36px">
                45,056 USD
              </Text>
              <Flex mt="4px" alignItems="center">
                <Text color="gray" fontWeight="500" fontSize="20px">
                  3034 MATIC
                </Text>

                <GrowTag />
              </Flex>
            </Flex>
            <Flex border="1px solid #DEDEDE" borderRadius="24px" padding="6px">
              <Box {...filterProps} color="white" bgColor="black">
                24h
              </Box>
              <Box {...filterProps}>7d</Box>
              <Box {...filterProps}>1m</Box>
              <Box {...filterProps}>6m</Box>
              <Box {...filterProps}>1y</Box>
              <Box {...filterProps}>All time</Box>
            </Flex>
          </Flex>
          <Box mt="34px">
            <img src={ChartBig} alt="big chart" />
          </Box>
        </GridItem>

        <GridItem colSpan={1} {...cardProps}>
          <Flex justifyContent="space-between" alignItems="center">
            <Text {...titleProps}>History</Text>
            <GrowTag />
          </Flex>
          <Flex mt="30px" flexDirection="column">
            {shopHistory.map((shop) => (
              <ShopInfoCard key={shop.title} {...shop} />
            ))}
          </Flex>
        </GridItem>

        <GridItem {...cardProps}>
          <Flex flexDirection="column" justifyContent="space-between" height="100%">
            <Box>
              <Text {...titleProps}>Balance</Text>
              <Text fontSize="48px" fontWeight="500">
                4.03 MATIC
              </Text>
            </Box>

            <Flex alignItems="center">
              <Avatar name="MATIC" src={MATICIcon} bgColor="gray" width="40px" height="40px" />

              <Box ml="12px">
                <Text fontSize="20px" fontWeight="500">
                  Polygon
                </Text>
                <Text mt="4px" fontSize="14px" fontWeight="400">
                  Public
                </Text>
              </Box>
            </Flex>
          </Flex>
        </GridItem>

        <GridItem {...cardProps}>
          <Text {...titleProps}>Sales statistic</Text>

          <Flex justifyContent="space-between" mt="8px">
            <Box>
              <Text color="gray.400" fontSize="14px">
                Received from sales
              </Text>
              <Text fontSize="20px" fontWeight="500">
                67,000USD
              </Text>
            </Box>
            <Box>
              <Text color="gray.400" fontSize="14px">
                Total income
              </Text>
              <Text color="green" size="16px" fontWeight="500">
                +23,74%
              </Text>
            </Box>
          </Flex>
          <Box mt="10px" width="100%">
            <img src={ChartSmall} alt="" />
          </Box>
        </GridItem>

        <GridItem {...cardProps}>
          <Text {...titleProps}>Top subscribe</Text>

          <Box mt="25px">
            <Text color="gray.400" fontSize="14px">
              In this month
            </Text>
            <Flex mt="8px" alignItems="center">
              <Box width="40px">
                <img src={AlexShopLogo} alt="Logo" />
              </Box>
              <Text fontSize="20px" fontWeight="500" ml="10px">
                Alex's Shop
              </Text>
              <Text ml="auto" color="green" fontSize="14px">
                +4.6 MATIC
              </Text>
            </Flex>

            <Text mt="16px" color="gray.400" fontSize="14px">
              All time
            </Text>
            <Flex mt="8px" alignItems="center">
              <Box width="40px">
                <img src={AlexShopLogo} alt="Logo" />
              </Box>
              <Text fontSize="20px" fontWeight="500" ml="10px">
                Alex's Shop
              </Text>
              <Text ml="auto" color="green" fontSize="14px">
                +13.01 MATIC
              </Text>
            </Flex>
          </Box>
        </GridItem>
      </Grid>
    </Box>
  );
};

const GrowTag = () => {
  return (
    <Flex
      border="1px solid #DEDEDE"
      borderRadius="24px"
      padding="2px 8px"
      ml="14px"
      alignItems="center"
    >
      <Box width="24px">
        <img src={GrowIcon} alt="Grow" />
      </Box>
      <Text ml="6px" fontSize="20px" fontWeight="500">
        3.23%
      </Text>
    </Flex>
  );
};

type ShopInfoCardProps = {
  title: string;
  description: string;
  logo: string;
  change: string;
  isGrow: boolean;
};
const ShopInfoCard = ({ title, logo, description, change, isGrow }: ShopInfoCardProps) => {
  return (
    <Flex
      _notLast={{
        pb: '12px',
        borderBottom: '1px solid #D9D9D9',
      }}
      _notFirst={{ mt: '12px' }}
    >
      <Box width="50px">
        <img src={logo} alt="Logo" />
      </Box>
      <Flex ml="10px" flexDirection="column">
        <Text fontSize="20px" fontWeight="500">
          {title}
        </Text>
        <Text fontSize="14px" fontWeight="500" color="gray.400" mt="4px">
          {description}
        </Text>
      </Flex>

      <Text color={isGrow ? 'green' : 'red'} fontSize="14px" fontWeight="500" ml="auto">
        {change}
      </Text>
    </Flex>
  );
};
