import { Link as RouterLink } from 'react-router-dom';
import { Box, Flex, Link } from '@chakra-ui/react';
import { useAccount } from 'wagmi';

import Logo from '@/assets/images/icons/logo.svg';
import { Profile } from '@/components/Profile/Profile';

import './Header.scss';

export const Header = () => {
  const { isConnected } = useAccount();
  return (
    <Flex
      className="app-header"
      padding="16px 24px"
      alignItems="center"
      justifyContent="space-between"
      height="80px"
    >
      <Flex>
        <Box>
          <Link as={RouterLink} to="/">
            <img src={Logo} alt="Subscrypt logo" width="100px" />
          </Link>
        </Box>
        {isConnected ? (
          <>
            <Link ml="32px" as={RouterLink} to="/dashboard">
              Dashboard
            </Link>
            <Link ml="32px" as={RouterLink} to="/my-subs">
              My Subscriptions
            </Link>
            <Link ml="32px" as={RouterLink} to="/my-shops">
              My Shops
            </Link>
            <Link ml="32px" as={RouterLink} to="/create">
              Create Shop
            </Link>
            <Link ml="32px" as={RouterLink} to="/balance">
              Balance
            </Link>
          </>
        ) : null}
      </Flex>
      <Profile />
    </Flex>
  );
};
