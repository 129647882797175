import { FC, useCallback } from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';
import { useConnect } from 'wagmi';

import { metamaskConnector } from '@/modules/wagmi';

type ConnectWalletButtonProps = {};
export const ConnectWalletButton: FC<ButtonProps & ConnectWalletButtonProps> = (props) => {
  const { connect, isLoading: isConnectLoading } = useConnect();

  const handleConnect = useCallback(async () => {
    connect({ connector: metamaskConnector });
  }, [connect]);

  return (
    <Button {...props} onClick={handleConnect} isLoading={isConnectLoading}>
      Connect wallet
    </Button>
  );
};
