import { Box, Flex, Text } from '@chakra-ui/react';
import { generateFromString } from 'generate-avatar';

type ShopCardProps = {
  id: string;
  title: string;
  description: string;
  logo: string;
  network: string;

  onClick?: () => void;
};
export const ShopCard = ({ id, title, description, logo, network, onClick }: ShopCardProps) => {
  return (
    <Box
      border="1px solid #DEDEDE"
      borderRadius="12px"
      padding="20px"
      height="280px"
      width="100%"
      onClick={onClick}
      transition="all 0.2s"
      _hover={onClick ? { borderColor: 'gray', cursor: 'pointer' } : {}}
    >
      <Flex flexDirection="column" justifyContent="space-between" height="100%">
        <Flex gap="16px" alignItems="center">
          <Box borderRadius="50%" width="50px" height="50px" bgColor="gray" overflow="hidden">
            {id ? (
              <img src={`data:image/svg+xml;utf8,${generateFromString(id)}`} alt="logo" />
            ) : null}
          </Box>
          <Flex gap="4px" justifyContent="space-between" alignItems="flex-start" direction="column">
            <Text fontSize="20px" fontWeight="600">
              {title}
            </Text>
            <Box border="1px solid #DEDEDE" borderRadius="16px" padding="0 12px" fontSize="12px">
              {network}
            </Box>
          </Flex>
        </Flex>

        <Text fontSize="20px">{description}</Text>
      </Flex>
    </Box>
  );
};
