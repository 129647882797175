import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AddIcon, CloseIcon, CopyIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Box,
  Button,
  Flex,
  Grid,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  Textarea,
} from '@chakra-ui/react';
import MATICIcon from 'cryptocurrency-icons/svg/color/matic.svg';
import { useSwitchNetwork } from 'wagmi';

import { ShopCard } from '@/components/ShopCard/ShopCard';
import { useCreateShop } from '@/hooks/useCreateShop';
import { useNotification } from '@/hooks/useNotification';
import { useSwitchNetworkToSpecific } from '@/hooks/useSwitchNetworkToSupported';

import { Integration, IntegrationsModal } from './IntegrationsModal';
import { Subscription, SubscriptionModal } from './SubscriptionModal';

export const CreateShop = () => {
  const navigate = useNavigate();
  const { success } = useNotification();
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const [integrations, setIntegrations] = useState<Integration[]>([]);
  const [shopName, setShopName] = useState('');
  const [shopDescription, setShopDescription] = useState('');
  const shopAddress = useMemo(() => {
    const basePath = 'subscrypt.org/';
    const path = shopName
      .split(' ')
      .map((part) => part.toLowerCase())
      .join('_');

    return basePath + path;
  }, [shopName]);

  const { shopId, startCreateShop, isLoading } = useCreateShop({
    title: shopName,
    description: shopDescription,
    subscriptions,
  });

  const { chains } = useSwitchNetwork();
  useSwitchNetworkToSpecific(chains[0]);

  const [isIntegrationsModalOpen, setIsIntegrationsModalOpen] = useState(false);
  const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = useState(false);

  const removeIntegration = useCallback((id: number) => {
    setIntegrations((items) => items.filter((item) => item.id !== id));
  }, []);
  const removeSubscription = useCallback((id: string) => {
    setSubscriptions((items) => items.filter((item) => item.id !== id));
  }, []);
  const handleSaveIntegrations = useCallback((newIntegrations: Integration[]) => {
    setIntegrations(newIntegrations);
    setIsIntegrationsModalOpen(false);
  }, []);
  const handleSubscriptionSave = useCallback((sub: Subscription) => {
    const id = Math.floor(Math.random() * 1000).toString();
    setSubscriptions((subs) => [...subs, { ...sub, id }]);
    setIsSubscriptionModalOpen(false);
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      const shopId = await startCreateShop();
      if (shopId) {
        setTimeout(() => {
          navigate(`/shop/${shopId}`);
        }, 3000);
      }
    } catch (e) {}
  }, [startCreateShop, navigate]);

  const copyShopAddress = useCallback(() => {
    success({ title: 'Copied' });
  }, [success]);

  return (
    <Box>
      <Text textStyle="h2">New Shop</Text>

      <Flex mt="12px" gap="16px">
        <Flex
          padding="24px"
          flexDir="column"
          border="1px solid #DEDEDE"
          borderRadius="16px"
          flexGrow="1"
        >
          <Flex alignItems="center">
            <Avatar name="MATIC" src={MATICIcon} bgColor="gray" />
            <Text ml="8px">POLYGON</Text>
          </Flex>

          <Flex alignSelf="center" mt="24px">
            <Flex
              borderRadius="50%"
              width="120px"
              height="120px"
              bgColor="gray"
              alignItems="center"
              justifyContent="center"
            >
              <Text color="white">Select logo</Text>
            </Flex>
          </Flex>

          <Flex flexDir="column" mt="24px">
            <Text>Shop name</Text>
            <Input type="text" value={shopName} onChange={(e) => setShopName(e.target.value)} />
          </Flex>

          <Flex flexDir="column" mt="24px">
            <Text>Shop address</Text>

            <InputGroup size="md">
              <Input pr="4.5rem" type="text" value={shopAddress} disabled />
              <InputRightElement width="4.5rem">
                <IconButton
                  h="1.75rem"
                  size="sm"
                  aria-label="copy"
                  icon={<CopyIcon />}
                  onClick={copyShopAddress}
                />
              </InputRightElement>
            </InputGroup>
          </Flex>

          <Flex flexDir="column" mt="24px">
            <Text>Description</Text>
            <Textarea
              rows={4}
              value={shopDescription}
              onChange={(e) => setShopDescription(e.target.value)}
            />
          </Flex>

          <Text textStyle="h3" mt="24px">
            Subscriptions
          </Text>
          <Grid gap="10px" mt="10px" templateColumns="repeat(2, 1fr)">
            {subscriptions.map((subscription) => (
              <SubscribePlan
                key={subscription.id}
                {...subscription}
                onDelete={() => removeSubscription(subscription.id)}
              />
            ))}
            {subscriptions.length < 3 ? (
              <Flex
                width="100%"
                minHeight="200px"
                border="1px dashed gray"
                borderRadius="12px"
                alignItems="center"
                justifyContent="center"
                _hover={{ bgColor: 'rgba(0,0,0,0.1)', cursor: 'pointer' }}
                onClick={() => setIsSubscriptionModalOpen(true)}
              >
                <AddIcon fontSize="32px" color="gray" />
              </Flex>
            ) : null}
          </Grid>

          <Text textStyle="h3" mt="24px">
            Integrations
          </Text>
          <Flex mt="12px" gap="12px">
            {integrations.map((integration) => (
              <IntegrationCard
                key={integration.id}
                {...integration}
                onRemove={() => removeIntegration(integration.id)}
              />
            ))}
            <Flex
              border="1px dashed gray"
              borderRadius="8px"
              padding="12px 16px"
              alignItems="center"
              justifyContent="center"
              _hover={{ bgColor: 'rgba(0,0,0,0.1)', cursor: 'pointer' }}
              onClick={() => setIsIntegrationsModalOpen(true)}
            >
              Add integration +
            </Flex>
          </Flex>

          <Button
            mt="24px"
            size="lg"
            onClick={handleSubmit}
            isLoading={isLoading}
            isDisabled={!shopName || !shopDescription || !subscriptions.length}
          >
            Submit
          </Button>
        </Flex>

        <Box width="400px">
          <ShopCard
            title={shopName}
            description={shopDescription}
            logo=""
            id={shopId || ''}
            network="POLYGON"
          />
        </Box>
      </Flex>

      {isSubscriptionModalOpen ? (
        <SubscriptionModal
          onClose={() => setIsSubscriptionModalOpen(false)}
          onSave={handleSubscriptionSave}
        />
      ) : null}

      {isIntegrationsModalOpen ? (
        <IntegrationsModal
          integrations={integrations}
          onClose={() => setIsIntegrationsModalOpen(false)}
          onSave={handleSaveIntegrations}
        />
      ) : null}
    </Box>
  );
};

type IntegrationCardProps = Integration & {
  onRemove: () => void;
};
const IntegrationCard = ({ title, value, logo, onRemove }: IntegrationCardProps) => {
  return (
    <Flex
      width="200px"
      border="1px dashed gray"
      borderRadius="8px"
      padding="12px 16px"
      alignItems="center"
      gap="12px"
      overflow="hidden"
    >
      <Box borderRadius="8px" width="32px" height="32px" flexShrink="0">
        <img src={logo} alt="" />
      </Box>

      <Flex flexDir="column">
        <Text textStyle="boldSmall">{title}</Text>
        <Text
          textStyle="textSmall"
          maxWidth="80px"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
        >
          {value}
        </Text>
      </Flex>

      <IconButton
        ml="auto"
        bgColor="transparent"
        color="black"
        border="none"
        aria-label="Remove integration"
        icon={<CloseIcon />}
        onClick={onRemove}
        size="sm"
      />
    </Flex>
  );
};

type SubscribePlanProps = Subscription & {
  onDelete: () => void;
};
const SubscribePlan = ({ period, price, description, onDelete }: SubscribePlanProps) => {
  return (
    <Flex
      border="1px solid gray"
      borderRadius="12px"
      padding="16px"
      flexDirection="column"
      width="100%"
    >
      <Flex justifyContent="space-between">
        <Text fontSize="18px" fontWeight="500">
          {period}
        </Text>

        <IconButton
          ml="auto"
          bgColor="transparent"
          color="black"
          border="none"
          aria-label="Remove subscription"
          icon={<CloseIcon />}
          onClick={onDelete}
          size="sm"
        />
      </Flex>
      <Flex mt="8px">
        <Text fontSize="32px" fontWeight="500">
          $
        </Text>
        <Text fontSize="54px" fontWeight="500" ml="4px">
          {price}
        </Text>
      </Flex>
      <Text fontSize="16px" fontWeight="500" color="gray">
        per month
      </Text>
      <Text fontSize="16px" fontWeight="500" mt="16px">
        {description}
      </Text>
    </Flex>
  );
};
