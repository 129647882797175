import { useCallback, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { formatEther, parseEther } from 'ethers';
import { useAccount, useContractRead, usePublicClient, useWalletClient } from 'wagmi';

import { ContractsEnum, useContractAbi } from './contracts/useContractAbi';
import { useApi } from './useApi';
import { useNotification } from './useNotification';

export const useMySubscriptions = () => {
  const { getWalletSubscriptions } = useApi();
  const { address, isConnected } = useAccount();

  const mySubscriptionsRequest = useQuery({
    queryKey: ['my-subscriptions-request', address],
    queryFn: async () => getWalletSubscriptions(address || ''),
    enabled: isConnected,
  });

  const mySubscriptions = useMemo(() => {
    return mySubscriptionsRequest.data?.data.data || [];
  }, [mySubscriptionsRequest.data]);

  return { mySubscriptions, mySubscriptionsRequest };
};

export const useSubscribe = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { success, handleError } = useNotification();
  const { address: ownerAddress, isConnected } = useAccount();
  const { createWalletSubscription } = useApi();

  const { address: accountsAddress, abi: accountsAbi } = useContractAbi({
    contract: ContractsEnum.Accounts,
  });
  const { address: paymentsAddress, abi: paymentsAbi } = useContractAbi({
    contract: ContractsEnum.Payments,
  });

  const publicClient = usePublicClient();
  const { data: walletClient } = useWalletClient();

  const { balanceWei } = useAccountBalance();

  const subscribe = useCallback(
    async (price: number, contractSubscriptionId: number, subscriptionId: string) => {
      if (isConnected && ownerAddress && walletClient) {
        try {
          setIsLoading(true);

          const priceWei: bigint = (await publicClient.readContract({
            address: paymentsAddress,
            abi: paymentsAbi,
            functionName: 'convertDollarsToWei',
            args: [price],
          })) as unknown as bigint;

          const finalPrice = priceWei + priceWei / 5n; // add 20%

          console.log(finalPrice, balanceWei);
          // Check balance of account
          // If balance less -> deposit to account
          if (finalPrice > balanceWei) {
            console.log('top up balance');

            const { request: depositRequest } = await publicClient.simulateContract({
              address: accountsAddress,
              abi: accountsAbi,
              functionName: 'deposit',
              account: ownerAddress,
              args: [],
              value: finalPrice - balanceWei,
            });

            console.log(depositRequest);

            const depositHash = await walletClient?.writeContract(depositRequest);

            await publicClient.waitForTransactionReceipt({ hash: depositHash });

            success({
              title: 'Success',
              description: 'Account balance increased',
              txHash: depositHash,
            });
          }

          // payments payForSubscription
          const { request: payRequest } = await publicClient.simulateContract({
            address: paymentsAddress,
            abi: paymentsAbi,
            functionName: 'payForSubscription',
            account: ownerAddress,
            args: [finalPrice, contractSubscriptionId],
          });

          const payHash = await walletClient?.writeContract(payRequest);

          await publicClient.waitForTransactionReceipt({ hash: payHash });

          // api POST create subscription
          createWalletSubscription(ownerAddress, subscriptionId);

          // open link with CCIP tx
          // window.open(`https://ccip.chain.link/address/${paymentsAddress}`, '_blank');

          success({ title: 'Success', description: 'You have been subscribed successfully' });
        } catch (err) {
          handleError(err);
          throw err;
        } finally {
          setIsLoading(false);
        }
      }
    },

    [
      publicClient,
      paymentsAbi,
      paymentsAddress,
      handleError,
      balanceWei,
      isConnected,
      ownerAddress,
      walletClient,
      accountsAbi,
      accountsAddress,
      success,
      createWalletSubscription,
    ]
  );

  return { subscribe, isLoading };
};

export const useAccountBalance = () => {
  const { address, isConnected } = useAccount();

  const { address: contractAddress, abi } = useContractAbi({
    contract: ContractsEnum.Accounts,
  });

  const contractRead = useContractRead({
    address: contractAddress,
    abi,
    functionName: 'getAccountBalance',
    args: [address],
    enabled: isConnected,
    watch: true,
  });

  const balanceWei = useMemo(() => contractRead.data as unknown as bigint, [contractRead.data]);
  const balanceEth = useMemo(() => formatEther(balanceWei || 0), [balanceWei]);

  return { contractRead, balanceEth, balanceWei };
};
